<template>
  <div>
    <div class="notification-wrapper">
      <div :class="{ 'blue-dot': notification.isUnread }" />
      <div class="content">
        <div class="header">
          <div class="flex items-left space-x-2">
            {{ getDateTime(notification.createdAt).date }}
          </div>
          <div class="date">
            {{ getDateTime(notification.createdAt).time }}
          </div>
        </div>
        <div class="text">
          <div
            v-if="notification.notificationType === 'push_data_submitted'"
            class="text"
          >
            <p class="title">
              Campaign:
              <router-link
                :to="{ name: 'CampaignSummary', params: { id: notification.campaignId }}"
                @click.native="handleClose()"
              >
                {{ notification.campaignName }}
              </router-link>
              has been submitted successfully and
              a{{ startsWithVowel(notification.externalSystemName) }} {{ notification.externalSystemName }}
              Order has been created!
            </p>
            <p class="message">
              A{{ startsWithVowel(notification.externalSystemName) }} {{ notification.externalSystemName }}
              Order was successfully created when this Campaign was submitted. Go to the campaign for more details.
            </p>
            <p class="company-group">
              {{ notification.companyGroup }}
            </p>
          </div>
          <div
            v-if="notification.notificationType === 'push_data_failed'"
            class="text"
          >
            <p class="title">
              The submission of Campaign:
              <router-link
                :to="{ name: 'CampaignSummary', params: { id: notification.campaignId }}"
                @click.native="handleClose()"
              >
                {{ notification.campaignName }}
              </router-link>
              has failed and
              a{{ startsWithVowel(notification.externalSystemName) }} {{ notification.externalSystemName }}
              Order was not created!
            </p>
            <p class="message">
              To review the reason for the error, please go to the Campaign for more details.
              A task with a due date of tomorrow has been created to help track this issue.
            </p>
            <p class="company-group">
              {{ notification.companyGroup }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    notification: {
      type: Object,
      required: false,
      default: null
    }
  },
  methods: {
    getDateTime (timestamp) {
      const date = new Date(timestamp)

      const year = date.getFullYear()
      const month = date.getMonth() + 1
      const day = date.getDate()

      const formattedDate = `${month}/${day}/${year}`
      const options = { hour: 'numeric', minute: 'numeric', hour12: true }
      const formattedDateTime = date.toLocaleString('en-US', options)
      return { date: formattedDate, time: formattedDateTime }
    },
    startsWithVowel (str) {
      const vowelCheck = /^[aeiou]/i

      if (str.match(vowelCheck)) return 'n'
      return ''
    },

    handleClose () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>

.notification-wrapper {
  display: flex;
  padding-top: 20px;
}

.content {
  flex-grow: 1;
}

.header {
  display: flex;
  justify-content: space-between;
  color: rgb(97, 91, 91);
}

.title {
  margin-bottom: 20px;
}

.message {
  margin-bottom: 20px;
}

.company-group {
  font-style: italic;
  color: rgb(97, 91, 91);
}

.space-x-2 {
  column-gap: 0.5rem;
}

.text {
  padding: 10px 0 10px 0 ;
  max-width: 415px;
  line-height: 1.5;
}

.blue-dot {
  min-width: 10px;
  height: 10px;
  border-radius: 100%;
  margin-top: 10px;
  margin-right: 8px;
  background-color: #2e76bc;
}

</style>
